export const featuredProjects = [
  {
    title: "Atlas",
    image: "images/project_images/Atlas/atlas.png",
    description:
      "Created a tracker device using arduino called Atlas. Users can track various items and view the location of the tracker within the mobile app.",
    technologies: [
      "React-Native",
      "Javascript",
      "MySQL",
      "GluestackUI",
      "SSE",
      "ExpressJS"
    ],
    links: {
      projectLink: undefined,
      github: "https://github.com/DaanyaalTahir/atlas-frontend",
    },
  },
  {
    title: "String Share",
    image: "images/project_images/String-Share/stringShare.png",
    description:
      "String share is a Social Media application that allows users to connect with one another in a clean and interactive user interface. Users can create posts, search for other users, comment/like posts, view other user profiles, and view or edit their own profile.",
    technologies: [
      "React-Native",
      "Javascript",
      "Postgres",
      "fastAPI",
      "python"
    ],
    links: {
      projectLink: undefined,
      github: "https://github.com/DaanyaalTahir/string-share",
    },
  },
  {
    title: "Microcomputers",
    image: "images/project_images/Microcomputers/Home.png",
    description:
      "Created a full-stack application for purchasing computer electronics. " +
      "Includes features such as account creation/sign-in, searching, viewing order history, accessing cart from previous session etc.",
    technologies: [
      "React",
      "Django",
      "Python",
      "Redux",
      "Javascript",
      "MySQL",
      "Bootstrap",
    ],
    links: {
      projectLink: undefined,
      github: "https://github.com/DaanyaalTahir/Micro_Computers",
    },
  },
  {
    title: "OT Autos",
    image: "images/project_images/OT-Autos/Search-Results.png",
    description:
      "Constructed full-stack application based on Kijiji Autos which allows users to buy or sell vehicles. " +
      "Allows users to create an account or sign-in, search for vehicles, post listings, update listings, etc.",
    technologies: [
      "React",
      "Node.js",
      "Express",
      "JavaScript",
      "MySQL",
      "Redux",
      "Bootstrap",
    ],
    links: {
      projectLink: undefined,
      github: "https://github.com/DaanyaalTahir/OT-Autos",
    },
  },
];

export const projects = [
  {
    title: "Movie Search",
    image: "images/project_images/Movie-Search/movFinder.png",
    description:
      "Completed Shopify Challenge where users can search for movies and nominate titles found in the OMDb API. Nominations can then be viewed in the Nomination tab where it will be displayed as a grid and the user has the option of removing selections",
    technologies: [
      "Angular",
      "Typescript ",
      "Angular Material",
      "HTML ",
      "CSS ",
      "OMBD API ",
    ],
    links: {
      projectLink: undefined,
      github: undefined,
    },
  },
  {
    title: "Inventory Manager",
    image: "images/project_images/Inventory-Manager/inventory-website.png",
    description:
      "Created a clean and modern Inventory Web Application that allows the user to add items with many parameter to a database, delete, update, and fetch them. The website was made responsive and incorporates many languages and technologies. ",
    technologies: [
      "PHP ",
      "ReactJS ",
      "Javascript ",
      "MySQL",
      "Bootstrap",
      " Apache Server ",
    ],
    links: {
      projectLink: undefined,
      github: undefined,
    },
  },
  {
    title: "Connect Four",
    image: "images/project_images/Connect-Four/connect4.png",
    description:
      "This Connect Four app was developed using Java and Android Studio. The game requires two players to play. A clean user interface was designed and multiple different animations were used",
    technologies: ["Java ", "Android Studio "],
    links: {
      projectLink: undefined,
      github: undefined,
    },
  },
];
