import React, { useRef, useState, useEffect } from "react";

import "./AboutMe.scss";
import { aboutMeText } from "../../generalObjects/AboutMe/about";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import { Fade } from "react-awesome-reveal";

function AboutMe() {
  const [showMoreDesc, setshowMoreDesc] = useState(false);
  const [heightExceededDesc, setheightExceededDesc] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    var height = elementRef.current.clientHeight;
    if (height > 300) setheightExceededDesc(true);
  }, []);

  const showMoreDescArrow = {
    transform: showMoreDesc ? "rotate(180deg)" : "",
    transition: "transform 150ms ease",
  };

  return (
    <div id={"About"} className="AboutMe_container">
      <div className="uncenter_row section_heading">
        <h2 className="primary_title_text">About Me</h2>
        <hr className="line_seperator" />
      </div>
      <Fade duration={2500} triggerOnce={true} cascade={true}>
        <div>
          <div
            className="about_me round_border"
            ref={elementRef}
            style={heightExceededDesc ? { paddingBottom: "0px" } : {}}
          >
            <div>
              <p
                className={`${
                  heightExceededDesc && !showMoreDesc && "text_ellipsis"
                }`}
              >
                {aboutMeText}
              </p>
            </div>
            {heightExceededDesc && (
              <div className="show_more_btn">
                <div style={showMoreDescArrow}>
                  <MdKeyboardArrowDown
                    onClick={() => setshowMoreDesc(!showMoreDesc)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default AboutMe;
