import React from "react";
import "./FeaturedProjectCard.scss";
import { Github, Link45deg } from "react-bootstrap-icons";

function FeaturedProjectCard({
  title,
  image,
  description,
  technologies,
  links,
  index,
}) {
  const { github, projectLink } = links;

  const isEven = index % 2 === 1;

  return (
    <div
      className={`FeaturedProjectCard_container round_border ${
        isEven ? "reverse" : ""
      }`}
    >
      <div className="project_details">
        <div className="title_section">
          <div>
            <h3 className="secondary_text">{title}</h3>
          </div>
          <div className="links">
            {github && (
              <a href={links.github} target="_blank">
                <Github />
              </a>
            )}
            {projectLink && (
              <a href={links.projectLink} target="_blank">
                <Link45deg />
              </a>
            )}
          </div>
        </div>
        <p className="description">{description}</p>
        <p>
          {technologies.map((tech) => {
            return <span className="technologies blue_text">{tech} </span>;
          })}
        </p>
      </div>
      <div className={`project_image_${isEven ? "left" : "right"} project_image`}>
        <img src={`${image}`} />
      </div>
    </div>
  );
}

export default FeaturedProjectCard;