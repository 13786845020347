import React from "react";

import { home } from "../../generalObjects/Homepage/home";
import { socialLinks } from "../../generalObjects/Socials/socialLinks";

import "./Homepage.scss";

function Homepage() {
  const { name, position } = home;

  return (
    <div className="Homepage_container" id="Home">
      <div
        id="main_cont"
        className="center_row"
        style={{ marginBottom: "100px" }}
      >
        <div className="image_border">
          <img
            className="headshot_image"
            alt="headshot_image"
            src={"images/profile-pic.jpg"}
          />
        </div>

        <div className="intro_container">
          <h2 className="primary_title_text">Hi there, my name's</h2>
          <h1 className="large_title_text">{name}</h1>
          <div className="row">
            <h2 className="primary_title_text">
              and I'm a <span className="dark_blue_text">{position[0]}</span>
            </h2>
          </div>

          <div className="center_row">
            {socialLinks.map((social, index) => {
              return (
                <a
                  href={social.link}
                  target="_blank"
                  rel="noreferrer"
                  className="contact_icons"
                  key={index}
                >
                  {social.icon}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
