import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";

import { iconNames } from "../../generalObjects/AboutMe/about";
import SkillIcon from "./components/SkillIcon";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import { Fade } from "react-awesome-reveal";

import "./Skills.scss";

function Skills() {
  const [showMoreSkills, setshowMoreSkills] = useState(false);
  const [heightExceededSkills, setheightExceededSkills] = useState(true);
  const [shownSkills, setShownSkills] = useState([]);

  useEffect(() => {
    if (showMoreSkills) {
      setShownSkills(iconNames);
    } else {
      setSkillsToShow();
    }
  }, [showMoreSkills]);

  const setSkillsToShow = () => {
    if (window.innerWidth >= 992) {
      setheightExceededSkills(false);
      setShownSkills(iconNames);
    } else if (window.innerWidth >= 768) {
      setShownSkills(iconNames.slice(0, 6));
    } else {
      setShownSkills(iconNames.slice(0, 4));
    }
  };

  const showMoreSkillsArrow = {
    transform: showMoreSkills ? "rotate(180deg)" : "",
    transition: "transform 150ms ease",
  };

  return (
    <div id="Skills" className="Skills_Cont">
      <div className="uncenter_row section_heading">
        <h2 className="primary_title_text">My Skills</h2>
        <hr className="line_seperator" />
      </div>
      <Fade duration={2500} triggerOnce={true} cascade={true}>
        <div>
          <div
            className={`skills_container ${showMoreSkills ? "expanded" : ""}`}
          >
            <div
              className="skills round_border"
              style={heightExceededSkills ? { paddingBottom: "0px" } : {}}
            >
              <Row style={{ alignItems: "center" }}>
                {shownSkills.map((icon, index) => {
                  return <SkillIcon icon={icon} key={index} />;
                })}
              </Row>
              {heightExceededSkills && (
                <div className="show_more_btn">
                  <div style={showMoreSkillsArrow}>
                    <MdKeyboardArrowDown
                      onClick={() => setshowMoreSkills(!showMoreSkills)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Skills;
