import React, { useState } from "react";
import "./Projects.scss";
import FeaturedProjectCard from "./components/FeaturedProjectCard";
import {
  featuredProjects,
  projects,
} from "../../generalObjects/Projects/projects";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import ProjectCard from "./components/ProjectCard";
import { Row } from "react-bootstrap";

import { Fade } from "react-awesome-reveal";

function Projects() {
  const [showProjects, setShowProjects] = useState(false);

  const arrowStyle = {
    transform: showProjects ? "rotate(180deg)" : "",
    transition: "transform 150ms ease",
  };

  return (
    <div className="Projects_container" id="Projects">
      <div className="uncenter_row section_heading">
        <h2 className="primary_title_text">Projects</h2>
        <hr className="line_seperator" />
      </div>
      <div className="featured_projects">
        <Fade cascade={true} damping={0.1} delay={100} triggerOnce={true}>
          {featuredProjects.map((project, index) => {
            return (
              <FeaturedProjectCard
                key={index}
                index={index}
                title={project.title}
                image={project.image}
                description={project.description}
                technologies={project.technologies}
                links={project.links}
              />
            );
          })}
        </Fade>

        <div className="show_more_btn">
          <h4 className="medium_text">Show more</h4>
          <div style={arrowStyle}>
            <MdKeyboardArrowDown
              onClick={() => setShowProjects(!showProjects)}
            />
          </div>
        </div>
        {showProjects && (
          <Fade cascade={true} damping={0.1} delay={100} triggerOnce={true}>
            <div className="projects_container">
              <Row className="projects_row">
                {projects.map((project, index) => {
                  return (
                    <ProjectCard
                      key={index}
                      title={project.title}
                      image={project.image}
                      description={project.description}
                      technologies={project.technologies}
                    />
                  );
                })}
              </Row>
            </div>
          </Fade>
        )}
      </div>
    </div>
  );
}

export default Projects;
