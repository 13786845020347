import React from "react";

import "./CompanyIcon.scss";

function CompanyIcon({ job, active, setActiveJob }) {
  return (
    <div
      className={
        job.companyName === active
          ? "company_icon active_icon round_border"
          : "company_icon round_border"
      }
      onClick={() => setActiveJob(job)}
    >
      <img alt="Company Icon" src={`${job.companyIcon}`} />
    </div>
  );
}

export default CompanyIcon;
