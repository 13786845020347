export const workExperience = [
  {
    companyName: "Nokia",
    companyIcon: "images/company_logos/nokia.png",
    jobPosition: "Software Developer",
    workPeriod: "May – August 2023",
    resumePoints: [
"Developed an efficient priority queue utility for a global notification system using Typescript and created React components in a module federated environment, ensuring timely delivery of important information to users.",      
      "Achieved 100% code coverage by writing comprehensive unit tests using Jest, resulting in a reduction of bugs.",
      "Built and published Docker images to Artifactory and managed Kubernetes pods through Helm charts, enabling seamless deployment of changes within the cluster.",
      "Strengthened security and accelerated fetch requests by 8x by implementing dynamic Role-Based Access Control (RBAC) and LRU Caching for ExpressJS endpoints."
    ],
  },
  {
    companyName: "Bell Canada",
    companyIcon: "images/company_logos/bell.png",
    jobPosition: "Software Developer",
    workPeriod: "May – August 2022",
    resumePoints: [
      "Advocated for and created a new web app for managing network devices for large corporations using technologies such as ReactJS, Django, Docker, PostgreSQL, HTML & CSS resulting in 9x faster speeds compared to the previous app that was utilized",
      "Implemented JWT authentication using KeycloakJS to provide secure login for the application",
      "Collaborated with network engineers to automate updating certificates for WLCs, switches, and APs for one of Canada’s largest banks by utilizing VueJS and Flask leading to updates being 6x faster than traditional methods",
      "Wrote unit/integration tests with pytest to ensure 100% test coverage thereby preventing bugs and regressions",
    ],
  },
  {
    companyName: "Rogers Communications ",
    companyIcon: "images/company_logos/Rogers.png",
    jobPosition: "Software Developer",
    workPeriod: "May – August 2021",
    resumePoints: [
      "Worked directly on sportsnet.ca which is the site for Canada’s largest sports media provider - Sportsnet (owned by Rogers) ",
      "Utilized ReactJS, react-router, redux, SCSS, HTML, JavaScript, and more to display up-to-date data to users ",
      "Created internal tools utilized by stakeholders using Next.js, NodeJS, AWS s3, JavaScript, etc. to allow them to download and validate game data by developing a backend API",
      "Collaborated in a team that included developers, UI Designers, Project Managers and QA Testers in agile scrum environment to construct Olympics, Football, WNBA, and Soccer pages on sportsnet.ca ",
      "Developed reusable modern components to be utilized throughout SportsNet and within internal tools",
      "Demoed work to stakeholders and other teams",
    ],
  },
  {
    companyName: "Completely Managed",
    companyIcon: "images/company_logos/CM.png",
    jobPosition: "Software Developer",
    workPeriod: "Jun – Dec 2020",
    resumePoints: [
      "Worked alongside 5 developers on website to display network analytics to customers",
      "Led and organized various projects as well as drove team meetings",
      "Interviewed potential candidates to help hire new staff, and later trained new hires",
      "Utilized Javascript, CSS, Redux and AngularJS as well as various libraries such as Chart.js to generate modern and clean UI’s such as a wizard for network scan process/configuration, scan scheduling, network results etc.",
      "Used NodeJS to create and redo backend API’s to be more efficient whilst performing network scans",
      "Displayed live feed of data for all running scans using WebSocket’s. ",
      "Refactored MySQL database table used for network scans to be more efficient/organized.",
    ],
  },
];
