export const aboutMeText =
  "My name is Daanyaal Tahir and I'm currenly attending Ontario Tech University for Software Engineering with an expected graduation date of April 2024. I am a hard-worker and succesfully acheived a 4.29 cGPA. Additionally I'm passionate about programming and am always willing to learn.";
export const iconNames = [
  "React",
  "Angular",
  "Vue",
  "Flask",
  "Django",
  "Javascript",
  "Python",
  "Java",
  "HTML",
  "MySQL",
  "AWS",
  "Express",
];
