import { Github, EnvelopeFill } from "react-bootstrap-icons";
import { RiLinkedinFill } from "react-icons/ri";

export const socialLinks = [
  {
    name: "linkedin",
    link: "https://www.linkedin.com/in/daanyaal-tahir-2485111b7/",
    icon: <RiLinkedinFill />,
  },
  {
    name: "github",
    link: "https://github.com/DaanyaalTahir",
    icon: <Github />,
  },
  {
    name: "email",
    link: "mailto:tahir.daanyaal@gmail.com",
    icon: <EnvelopeFill />,
  },
];
