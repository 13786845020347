import "./App.css";
import NavBar from "./components/NavBar";
import Homepage from "./components/Homepage";
import AboutMe from "./components/AboutMe";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Skills from "./components/Skills";
import "bootstrap/dist/css/bootstrap-grid.min.css";
function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="content_container">
        <Homepage />
        <AboutMe />
        <Skills />
        <Experience />
        <Projects />
        <Contact />
      </div>
    </div>
  );
}

export default App;
